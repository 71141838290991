/*===========================
    COUNTER SCSS 
===========================*/
.counter-section {
	&.mt-negative {
		margin-top: -150px;
		position: relative;
		z-index: 22;
	}
	.counter-inner {
		position: relative;
		background-color: $primary-color;
		padding: 100px 0;
		margin-bottom: 20px;
		@media #{$md} {
			padding: 60px 0;
		}
		@media #{$sm} {
			padding-top: 60px;
			padding-bottom: 20px;
		}
		&::after {
			position: absolute;
			content: '';
			background-color: $secondary-color;
			left: 20px;
			right: 20px;
			bottom: -20px;
			top: 20px;
			z-index: -2;
		}
		.counter-box {
			text-align: center;
			position: relative;
			z-index: 4;
			@media #{$sm} {
				margin-bottom: 40px;
			}
			h1 {
				font-size: 70px;
				margin-bottom: 10px;
				@media #{$md} {
					font-size: 50px;
				}
				@media #{$sm} {
					font-size: 50px;
				}
			}
			.title {
				font-size: 18px;
				text-transform: uppercase;
				font-family: $oswald;
				font-weight: 700;
				@media #{$md} {
					font-size: 16px;
				}
				@media #{$sm} {
					font-size: 16px;
				}
			}
		}
		span.big-text {
			font-size: 240px;
			font-family: $oswald;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 1;
			text-align: center;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: transparent;
			-webkit-text-fill-color: transparent;
			-webkit-text-stroke: 1px $white;
			z-index: 1;
			opacity: 0.2;
			@media #{$md} {
				font-size: 200px;
			}
			@media #{$sm} {
				font-size: 120px;
			}
			@media #{$tiny} {
				font-size: 80px;
			}
		}
	}
}
