/*===========================
    Footer
===========================*/
footer {
	background-color: $fourth-color;
	.social-icon {
		@media #{$xsm} {
			margin-top: 30px;
		}
		a {
			text-align: center;
			background-color: $fourth-color;
			color: $white;
			height: 60px;
			width: 60px;
			line-height: 60px;
			border-radius: 50%;
			margin-left: 10px;
			@media #{$sm} {
				font-size: 15px;
				line-height: 45px;
				height: 45px;
				width: 45px;
			}
			@media #{$tiny} {
				margin-left: 5px;
			}
			&:hover {
				background-color: $primary-color;
				color: $fourth-color;
			}
		}
	}
	.footer-top {
		background-color: $third-color;
		padding: 40px 0;
	}
	.footer-widget-area {
		padding-top: 100px;
		padding-bottom: 40px;
		.widget {
			margin-bottom: 50px;
			&,
			a {
				color: $white;
			}
			a:hover {
				color: $primary-color;
			}
			.widget-title {
				font-size: 20px;
				text-transform: uppercase;
				color: $white;
				margin-bottom: 40px;
				@media #{$md} {
					margin-bottom: 30px;
				}
				@media #{$sm} {
					margin-bottom: 30px;
				}
			}
			&.nav-widget {
				ul li {
					line-height: 38px;
				}
			}
			&.recent-post {
				.post-loop {
					.post {
						display: grid;
						grid-template-rows: auto;
						grid-template-columns: 70px 1fr;
						grid-column-gap: 20px;
						align-items: center;
						padding-bottom: 20px;
						margin-bottom: 20px;
						border-bottom: 1px solid #283742;
						@media #{$lsm} {
							grid-column-gap: 10px;
						}
						&:last-child {
							margin-bottom: 0;
							padding-bottom: 0;
							border: none;
						}
						.post-img {
							max-width: 70px;
							max-height: 70px;
						}
						.time {
							font-size: 12px;
							color: #96abbb;
							text-transform: uppercase;
							font-family: $oswald;
							font-weight: 700;
							i {
								color: $primary-color;
								margin-right: 2px;
							}
						}
						h5 {
							font-size: 20px;
							line-height: 1.3;
							color: $white;
							text-transform: uppercase;
							@media #{$lg} {
								font-size: 16px;
							}
							@media #{$lsm} {
								font-size: 14px;
							}
							@media #{$xsm} {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
	.copy-right-area {
		.copyrigt-text {
			padding: 35px 0;
			border-top: 1px solid #283742;
			font-weight: 700;
			font-size: 18px;
			color: $white;
			@media #{$sm} {
				font-size: 16px;
			}
			@media #{$xsm} {
				text-align: center;
				p {
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			a {
				color: $white;
				&:hover {
					color: $primary-color;
				}
			}
		}
	}
	/*===== Footer Two =====*/
	&.footer-style-two {
		background-color: $text-color;
		.footer-widget-area {
			padding-bottom: 50px;

			.foter-logo {
				margin-bottom: 50px;
				@media #{$sm} {
					margin-bottom: 80px;
				}
			}

			.widget {
				padding: 50px 35px;
				text-align: center;
				line-height: 38px;

				@media #{$lg} {
					padding: 45px 10px;
				}
				@media #{$lsm} {
					padding: 0;
				}
				@media #{$xsm} {
					padding: 0 30px;
				}
				@media #{$tiny} {
					padding: 0 15px;
				}

				.widget-title {
					font-size: 30px;
				}
				&.about-widget {
					@media #{$lsm} {
						font-size: 15px;
						line-height: 30px;
					}
				}
				&.getin-widget {
					background-color: $primary-color;
					font-weight: 700;
					@media #{$sm} {
						padding: 50px 0;
					}
					li {
						line-height: 36px;
					}
					&,
					.widget-title {
						color: $secondary-color;
					}
					.getin-btn {
						font-family: $oswald;
						font-weight: 700;
						font-size: 14px;
						padding: 10px 45px;
						text-transform: uppercase;
						background-color: $secondary-color;
						display: inline-block;
						margin-top: 30px;
						&:hover {
							background-color: $secondary-color;
							color: $white;
						}
					}
				}
			}
		}
		.copy-right-area {
			background-color: #13131c;
			padding: 15px 0;
			@media #{$sm} {
				padding: 20px 0;
			}
			.copyrigt-text {
				padding: 0;
				border: none;
			}
			.social-icon {
				@media #{$sm} {
					margin-top: 15px;
				}
				a {
					background-color: $text-color;
				}
			}
		}
	}
	/*===== Footer Three =====*/
	&.footer-style-three {
		background-color: $white;
		.footer-top {
			background-color: $section-bg;
			.social-icon {
				a {
					background-color: $white;
					color: $secondary-color;
					&:hover {
						background-color: $primary-color;
					}
				}
			}
		}
		.footer-widget-area {
			.widget {
				&,
				a {
					color: $text-color;
				}
				a:hover {
					color: $primary-color;
				}
				.widget-title {
					color: $secondary-color;
				}
				&.recent-post .post-loop .post {
					border-color: #f2f3f4;
					.time {
						color: $primary-color;
					}
				}
			}
		}
		.copy-right-area {
			.copyrigt-text {
				color: $text-color;
				border-color: #d8e0e9;
			}
			a {
				color: $text-color;
				&:hover {
					color: $primary-color;
				}
			}
		}
	}
}

/*===== Go Top =====*/
.go-top-area {
	position: relative;
	z-index: 9999;
	.go-top {
		position: fixed;
		cursor: pointer;
		top: 0;
		right: 24px;
		color: #ffffff;
		background-color: $primary-color;
		z-index: 9999;
		width: 40px;
		text-align: center;
		height: 42px;
		line-height: 42px;
		opacity: 0;
		visibility: hidden;
		@include transition(0.9s);
		border-right: 0;
		i {
			position: absolute;
			top: 50%;
			left: -4px;
			right: 0;
			margin: 0 auto;
			font-size: 15px;
			@include transform(translateY(-50%));
			@include transition(0.5s);

			&:last-child {
				opacity: 0;
				visibility: hidden;
				top: 60%;
			}
		}
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			background-color: $primary-color;
			opacity: 0;
			visibility: hidden;
			@include transition(0.5s);
		}
		&:focus,
		&:hover {
			color: $white;
			&::before {
				opacity: 1;
				visibility: visible;
			}
			i {
				&:first-child {
					opacity: 0;
					top: 0;
					visibility: hidden;
				}
				&:last-child {
					opacity: 1;
					visibility: visible;
					top: 50%;
				}
			}
		}
		&.active {
			top: 98%;
			@include transform(translateY(-98%));
			opacity: 1;
			visibility: visible;
			border-radius: 0;
			right: 20px;
			@media #{$lg} {
				top: 93%;
			}
			@media #{$md} {
				top: 93%;
			}
		}
	}
}
.go-top-wrap {
	position: relative;
	.go-top-btn {
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		color: $white;
		top: 3px;
		z-index: 1;
		background: $primary-color;

		i {
			font-size: 20px;
			font-weight: 700;
			padding-left: 4px;
			color: $white;
		}

		&::after {
			z-index: -1;
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 40px;
			height: 40px;
			@include animation(ripple 1.6s ease-out infinite);
			opacity: 0;
			background: $primary-color;
		}

		&:hover {
			background-color: $black;
			color: $white;
		}
	}
}
