/*===========================
    CONTACT
===========================*/
.contact-form-wrapper {
	box-shadow: 0px 16px 32px 0px rgba(146, 211, 215, 0.16);
	.contact-form {
		padding: 60px;
		background-color: $secondary-color;
		@media #{$md} {
			padding: 30px;
		}

		@media #{$xsm} {
			padding: 30px;
		}

		input,
		textarea {
			background: #111117;
			border: none;
			width: 100%;
			height: 68px;
			padding-left: 65px;
			padding-right: 30px;
			font-size: 14px;
			text-transform: none;
			font-weight: 400;
			font-family: $roboto;
		}
		.padding-custom {
			&.row {
				margin-left: -5px;
				margin-right: -5px;
			}
			& > .col,
			& > [class*='col-'] {
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		textarea {
			padding-top: 25px;
			height: 192px;
		}

		::placeholder,
		input,
		textarea {
			color: $white;
			opacity: 1;
		}

		.input-group {
			.icon {
				left: 40px;
			}
			&.textarea {
				.icon {
					top: 25px;
					transform: translateY(0);
				}
			}
		}
		.main-btn.btn-filled {
			margin-top: 10px;
			&:hover {
				background-color: $white;
				border-color: $white;
				&::before,
				&::after {
					background-color: $white;
				}
			}
		}
	}
	.contact-info-list {
		background-color: $white;
		padding: 60px;
		height: 100%;
		@media #{$md} {
			padding: 30px;
		}
		@media #{$xsm} {
			padding: 30px;
		}
		.info-box {
			display: grid;
			grid-template-rows: 1;
			grid-template-columns: 65px 1fr;
			margin-bottom: 30px;
			padding-bottom: 30px;
			border-bottom: 2px solid #f3f3f3;
			@media #{$md} {
				font-size: 15px;
			}
			@media #{$tiny} {
				font-size: 15px;
			}
			&:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
				border: none;
			}
			.icon {
				font-size: 40px;
				color: $primary-color;
				line-height: 1;
			}
			h4 {
				margin-bottom: 20px;
				text-transform: uppercase;
				@media #{$md} {
					font-size: 20px;
				}
				@media #{$tiny} {
					font-size: 20px;
				}
			}
		}
	}
}
/* contact section */
.contact-section {
	&.with-map-bg {
		position: relative;
		z-index: 1;
		&::after {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			content: '';
			height: 100%;
			width: 100%;
			background-image: url(../img/map.png);
			background-size: contain;
			background-position: left top;
			background-repeat: no-repeat;
		}
	}

	.section-title {
		@media #{$xsm} {
			padding-left: 30px;
		}
		h2 {
			@media #{$lsm} {
				font-size: 32px;
			}
		}
	}
}
/* Contact Maps */
.contact-maps {
	width: 100%;
	height: 600px;
	@media #{$md} {
		height: 500px;
	}
	@media #{$sm} {
		height: 400px;
	}
	iframe {
		width: 100%;
		height: 100%;
	}
}
