/*===========================
	Feature section
===========================*/
.features-boxes {
	position: relative;
	.features-boxes-loop {
		@media #{$xsm} {
			margin-top: 50px;
		}
		.feature-box {
			background-color: $white;
			box-shadow: 0px 10px 20px 0px rgba(155, 180, 204, 0.06);
			padding: 30px 35px;
			margin-bottom: 40px;
			@media #{$lg} {
				padding: 30px 20px;
			}
			@media #{$md} {
				padding: 30px 20px;
			}
			@media #{$tiny} {
				padding: 30px 20px;
			}
			h4 {
				text-transform: uppercase;
				margin-bottom: 20px;
				@media #{$lg} {
					font-size: 20px;
				}
				@media #{$md} {
					font-size: 20px;
				}
				@media #{$tiny} {
					font-size: 20px;
				}
				i {
					color: $primary-color;
					margin-right: 15px;
					@media #{$md} {
						margin-right: 5px;
					}
					@media #{$tiny} {
						margin-right: 10px;
					}
				}
			}
		}
	}
	.gap-for-img {
		@media #{$lsm} {
			height: 215px;
			width: 100%;
		}
		@media #{$xsm} {
			height: 130px;
			width: 100%;
		}
		@media #{$tiny} {
			display: none;
		}
	}
	.feature-img {
		position: absolute;
		left: 50%;
		bottom: 120px;
		transform: translateX(-50%);
		z-index: -1;
		@media #{$md} {
			bottom: 150px;
			max-width: 385px;
		}
		@media #{$lsm} {
			bottom: 42%;
			transform: translate(-50%, 50%);
			max-width: 290px;
		}
		@media #{$xsm} {
			bottom: 45%;
			transform: translate(-50%, 50%);
			max-width: 200px;
		}
		@media #{$tiny} {
			display: none;
		}
	}
	.section-title {
		@media #{$xsm} {
			padding-left: 35px;
		}
		@media #{$tiny} {
			padding-left: 20px;
		}
	}
}
.features-boxes-two {
	&.pt-180 {
		padding-top: 120px;
	}
	.feature-text {
		@media #{$lg} {
			padding-left: 30px;
		}
		@media #{$md} {
			padding-left: 0;
			margin-top: 50px;
		}
		@media #{$sm} {
			padding-left: 0;
			margin-top: 50px;
		}
		.feature-icon {
			li {
				display: inline-block;
				margin-right: 75px;
				text-align: center;
				@media #{$lg} {
					margin-right: 20px;
				}
				@media #{$xsm} {
					margin-right: 20px;
				}
				@media #{$tiny} {
					margin-bottom: 30px;
					display: block;
					text-align: left;
				}
				h6 {
					text-transform: uppercase;
					margin-top: 15px;
					@media #{$xsm} {
						font-size: 14px;
					}
				}
				&:hover {
					img {
						animation: hvr-buzz-out 0.75s linear 1;
					}
				}
			}
		}
	}
	.features-boxes-loop {
		.feature-box-two {
			background-color: $white;
			padding: 50px 30px;
			font-size: 14px;
			position: relative;
			z-index: 1;
			box-shadow: 0px 10px 20px 0px rgba(179, 202, 216, 0.1);

			@media #{$lg} {
				padding: 50px 15px;
			}
			@media #{$xsm} {
				padding: 50px 60px;
			}
			@media #{$tiny} {
				padding: 50px 30px;
			}

			h4 {
				text-transform: uppercase;
				margin-bottom: 25px;
			}
			.icon {
				margin-bottom: 40px;
				i {
					color: $secondary-color;
					background-color: $primary-color;
					text-align: center;
					height: 70px;
					width: 70px;
					line-height: 70px;
					font-size: 24px;
					@include transition(0.3s);
				}
			}
			&:hover {
				.icon i {
					color: $white;
					background-color: $secondary-color;
				}
			}
			.count {
				position: absolute;
				right: 0;
				bottom: 0;
				line-height: 1;
				color: #eef6fa;
				font-size: 80px;
				font-family: $oswald;
				font-weight: 700;
				z-index: -1;
			}
		}
	}
}
