// Font Family
$oswald: 'Oswald', sans-serif;
$roboto: 'Roboto', sans-serif;

// Colors ---------------
$text-color:#1e1e2a;
$primary-color: #C3E200;
$secondary-color: #1e1e2a;
$overly-color: #757a89;
$third-color: #13131c;
$fourth-color: #1e1e2a;
$section-bg: #f7fcff;
$white: #fff;
$black: #000;

$border-color: #ededed;

// Responsive Variables
$xxl: '(min-width: 1600px)';
$xl: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg: 'only screen and (min-width: 992px) and (max-width: 1200px)';
$md: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm: '(max-width: 767px)';
$lsm: 'only screen and (min-width: 576px) and (max-width: 767px)';
$xsm: '(max-width: 575px)';
$tiny: '(max-width: 399px)';
